import { ProviderGetImage } from '@nuxt/image';

const getImageSrc = (
  src: string,
  resource = 'product',
  width = 320,
  height = width
) => {
  if (!src.includes('bigcommerce')) {
    return src;
  }

  let path;
  let baseURL = '';

  switch (resource) {
    case 'product':
      /* Example input:
       * - From search: https://cdn11.bigcommerce.com/s-zxwmqgdo7s/products/5577/images/10411/0931__74531.1635173338__35523.1635950074.386.513.jpg
       * - From product page: https://cdn11.bigcommerce.com/s-zxwmqgdo7s/images/stencil/original/products/5577/10411/0931__74531.1635173338__35523.1635950074.jpg
       *
       * Example output:
       * https://cdn11.bigcommerce.com/s-zxwmqgdo7s/images/stencil/320w/products/5577/10411/0931__74531.1635173338__35523.1635950074.jpg
       */

      [baseURL] = src.includes('/images/stencil')
        ? src.split('/images/stencil')
        : src.split('/products');
      [, path] = src.split('products/');

      return `${baseURL}/images/stencil/${width}x${height}/products/${path.replace(
        '/images',
        ''
      )}?webp`;
    case 'banner':
      /* Example input:
       * - https://cdn11.bigcommerce.com/s-zxwmqgdo7s/product_images/uploaded_images/green.jpeg
       *
       * Example output:
       * https://cdn11.bigcommerce.com/s-zxwmqgdo7s/images/stencil/320w/image-manager/green.jpeg
       */

      [baseURL, path] = src.split('/product_images/uploaded_images/');

      return `${baseURL}/images/stencil/${width}w/image-manager/${path}?webp`;
    default:
      return src;
  }
};

export const getImage: ProviderGetImage = (src, { modifiers } = {}) => {
  const { width, height, resource } = modifiers || {};

  return {
    url: getImageSrc(src, resource, width, height),
  };
};
